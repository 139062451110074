/* Previe Styles  */


.main-wrapper {
    position: relative;
    background: #ffffff;
}
.prv-header-area {
    padding: 18px 85px;
    padding-top: 18px;
}
.fullscreen{
    &.empty-div{
        @media #{$md-layout} {
            display: none;
        }
        @media #{$lg-layout} {
            display: none;
        }
    }
}


.gradient-overlay {
    position: relative;
}

.gradient-overlay::before {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    content: "";
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+7,000000+100&0+50,0.5+100 */
    background: -moz-linear-gradient(-45deg,  rgba(0,0,0,0) 7%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  rgba(0,0,0,0) 7%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  rgba(0,0,0,0) 7%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */


}

.prv-banner-wrapper{
    min-height: 100vh;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    @media #{$sm-layout} {
        min-height: auto;
        position: static;
        padding: 60px 0;
        background-image: none !important;
        background-color: #f6f6f6;
    }
    @media #{$md-layout} {
        min-height: auto;
        position: static;
        padding: 80px 0;
        background-image: none !important;
        background-color: #f6f6f6;
    }
    @media #{$lg-layout} {
        min-height: auto;
        position: static;
        padding: 80px 0;
        background-image: none !important;
        background-color: #f6f6f6;
    }
    @media #{$md-layout} {
      
    }
    @media #{$lg-layout} {
        
    }
    .inner{
        padding: 35px;
        position: relative;
        z-index: 1;
        @media #{$laptop-device} {
            padding: 35px 0;
        }
        @media #{$sm-layout} {
            padding: 0;
        }
        .logo {
            img{
                max-height: 70px;
            }
        }
        .title {
            font-size: 24px;
            color: #515462;
            line-height: 48px;
            margin: 30px 0 50px 0;
            @media #{$laptop-device} {
                font-size: 20px;
                line-height: 36px;
                margin: 24px 0 40px 0;
            }
            @media #{$sm-layout} {
                margin: 32px 0 20px 0;
            }

        }
        .purshase-btn{
            margin-left: -15px;
            margin-right: -15px;
            @media #{$laptop-device} {
                margin-left: -10px;
                margin-right: -10px;
            }
            @media #{$sm-layout} {
                margin-left: -5px;
                margin-right: -5px;
            }
            a{
                margin-left: 15px;
                margin-right: 15px;
                @media #{$sm-layout} {
                    margin-left: 5px;
                    margin-right: 5px;
                }
                &.rn-button-style--2 {
                    @media #{$laptop-device} {
                        padding: 10px 25px;
                    }
                    @media #{$sm-layout} {
                        padding: 9px 12px;
                        font-size: 14px;
                    }
                }
                @media #{$laptop-device} {
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
    }

}

/* Demo Area  */

.home-demo-area{
    .wrapper {
        @media #{$md-layout} {
            padding: 0 30px !important;
        }
        @media #{$sm-layout} {
            padding: 0 30px !important;
        }
    }
    .section-title {
        &.text-white {
            h2 {
                color: #c6c9d8;
                margin-bottom: 9px;
            }
            p {
                color: #c6c9d8;
                opacity: 0.75;
                font-weight: 300;
            }
        }
    }
}
// Start Single Demo 
.bg_color--7{
    .single-demo {
        a {
            span {
                display: block;
                text-align: center;
                padding: 20px 0;
                color: rgba(255,255,255,0.7);
                font-size: 20px;
                font-weight: 500;
                transition: 0.3s ease-in-out;
                letter-spacing: .20px;
            }
        }
    }  
}
.single-demo {
    padding: 0 30px;
    margin: 45px 0;
    
    @media #{$laptop-device} {
        padding: 0px;
    }
    @media #{$md-layout} {
        padding: 0px;
    }
    @media #{$sm-layout} {
        padding: 0px;
        margin: 20px 0;
    }
    @media #{$lg-layout} {
        padding: 0px;
    }
    a {
        display: block;
        @extend %transition;
        position: relative;
        z-index: 2;
        img {
            box-shadow: 3px 4px 20px 0 rgba(0, 0, 0, 0.1);
            transition: 0.7s cubic-bezier(0.26, 0.76, 0.30, 0.75);
            width: 100% !important;
            //padding: 14px 0 0 0;
            //background-image: url(/assets/images/preview/browser-image-top.jpg);
            //background-size: contain;
            //background-position: top left;
            //background-repeat: no-repeat;
        }
        .title {
            display: block;
            text-align: left;
            padding: 25px 0 10px 0;
            color: rgba(0, 0, 0, 0.7);
            font-size: 20px;
            font-weight: 500;
            transition: 0.3s ease-in-out;
            letter-spacing: .20px;
            margin: 0;
        }
        .info {
            display: block;
            padding: 0;
            color: rgba(0, 0, 0, 0.5);
            font-size: 14px;
            font-weight: 300;
            transition: 0.3s ease-in-out;
            line-height: 25px;
        }
        span {
            &.number {
                display: none;
                position: absolute;
                color: #f6f6f6;
                z-index: -1;
                font-size: 81px;
                bottom: -55px;
                line-height: inherit;
                font-weight: 300;
                letter-spacing: 11px;
            }
        }
        &:hover {
            img {
                -webkit-transform: translate3d(0,-10px,0);
                transform: translate3d(0,-10px,0);
                box-shadow: 0 50px 80px -10px rgba(0,0,0,0.17);
            }
        }
    }
}




/* Footer Style  */


.prv-footer{
    .inner{
        h2{
            &.title {
                color: $heading-color;
                font-size: 60px;
                font-weight: 500;
                margin-bottom: 65px;
            }
        }
    }
}


a {
    &.purchase-btn {
        display: inline-block;
        padding: 0 75px;
        height: 70px;
        line-height: 70px;
        border-radius: 43px;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        transition: 0.4s ease-in-out;
        color: #ffffff;
        background: $heading-color;
        border: 1px solid $heading-color;
        &:hover {
            color: $heading-color;
            border: 1px solid $heading-color;
            background: transparent;
        }
    }
}


/* Feature Area */ 
.prv-feature{
    //text-align: center;
    //.icon{
    //    background: $heading-color;
    //    height: 150px;
    //    width: 150px;
    //    line-height: 150px;
    //    text-align: center;
    //    border-radius: 100%;
    //    margin: 0  auto;
    //    svg {
    //        color: $theme-color;
    //        font-size: 62px;
    //    }
    //}
    .content {
        //padding: 16px 0 0;
        h3 {
            &.title {
                color: #c6c9d8;
                @media #{$md-layout} {
                    margin-bottom: 13px;
                    font-size: 20px;
                }
                @media #{$sm-layout} {
                    margin-bottom: 13px;
                    font-size: 20px;
                }
            }
        }
        p {
            &.subtitle{
                color: #c6c9d8;
                @media #{$md-layout} {
                    font-size: 16px;
                    line-height: 28px;
                }
                @media #{$sm-layout} {
                    font-size: 16px;
                    line-height: 28px;
                }
            }
        }
    }
}


.text-white-wrapper{
    a{
        &.rn-button-style--2{
            border: 2px solid #fff;
            color: #fff;
        }
    }
}
